<template>
  <b-modal
    id="copy-requirement-modal"
    title="Copy Requirement"
    size="lg"
    class="p-0"
    lazy
    no-close-on-esc
    no-close-on-backdrop
    @show="onShow"
    @ok.prevent="onSubmit"
  >
    <div>
      <h5 class="text-primary">
        Copying {{ titleSuffix }}
      </h5>
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scroll-area-idea-entities mb-0"
        style="max-height: 10rem"
      >
        <div class="font-small-3 ml-1 text-body" v-sanitized-html="bodyText" />
      </vue-perfect-scrollbar>

      <h6 class="mt-2 text-primary">
        1. Select target Specification to Copy to
      </h6>
      <SpecificationPicker v-model="targetSpecification" />

      <h6 class="mb-0 mt-2 text-primary">
        2. Select Target Requirement
      </h6>
      <span class="text-muted" style="font-size: 0.9em">
        Leave unselected to use specification as target
      </span>
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scroll-area-idea-entities"
        style="max-height: 20rem"
      >
        <b-table
          responsive
          striped
          hover
          selectable
          show-empty
          empty-text="The selected specification is empty, requirements will be placed from the section 1."
          select-mode="single"
          class="position-relative"
          :fields="requirementTableFields"
          :items="requirementsList"
          @row-selected="rows => targetRequirement = rows[0] && rows[0].id ? rows[0] : null"
        />
      </vue-perfect-scrollbar>

      <div v-if="selectedRequirements.length === 1" class="d-inline-flex w-100 justify-content-around">
        <b-form-checkbox v-model="includeChildren" name="check-button" switch>
          Include all children
        </b-form-checkbox>
        <b-form-checkbox v-model="traceToOriginal" name="check-button" switch>
          Create trace to original
        </b-form-checkbox>
      </div>

      <h6 class="mt-2 text-primary">
        3. Relationships to other objects
      </h6>
      <b-form>
        <b-container>
          <p>Copy links to...</p>
          <b-row>
            <b-col>
              <b-form-checkbox-group
                v-model="copyLinks"
                style="column-count: 2"
                class="custom-control-info"
                :options="linkOptions"
                switches
                stacked
                :checked="copyLinks"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-form>
      <h6 class="mt-2 text-primary">
        4. Relationship to Target Requirement
      </h6>
      <div class="mt-50 ml-2">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            v-model="relation"
            :disabled="targetRequirement === null"
            :options="['Before', 'After', 'Child']"
            :aria-describedby="ariaDescribedby"
          />
        </b-form-group>
      </div>
    </div>
    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button
        :disabled="loading"
        variant="outline-secondary"
        @click="cancel"
      >
        Discard
      </b-button>
      <b-button variant="success" :disabled="loading" @click="onSubmit">
        <span v-if="loading">
          <b-spinner small type="grow" />
          Copying...
        </span>

        <span v-else>
          Copy Requirement
        </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>

import coreService from '@/libs/api-services/core-service'
import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import SpecificationPicker from '@/components/Specifications/SpecificationPicker.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CopyRequirementsModal',
  components: {
    VuePerfectScrollbar,
    SpecificationPicker,
  },
  props: {
    selectedRequirements: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props, context) {
    const loading = ref(false)
    const titleSuffix = ref('')
    const bodyText = ref('')
    const perfectScrollbarSettings = { maxScrollbarLength: 60 }
    const requirementTableFields = [
      { key: 'section', label: 'Section' },
      { key: 'display_id', label: 'Display ID' },
      { key: 'priority', label: 'Priority' },
    ]
    const linkOptions = [
      { text: 'Components', value: 'ALLOCATED_TO:Component' },
      { text: 'Interfaces', value: 'ALLOCATED_TO:Interface' },
      { text: 'Behaviour Nodes', value: 'ALLOCATED_TO:BehaviourNode' },
      { text: 'Releases', value: 'ALLOCATED_TO:Release' },
      { text: 'Issues', value: 'HAS_ISSUE' },
      { text: 'Notes', value: 'HAS_NOTE' },
      { text: 'Tests', value: 'TESTED_BY' },
      { text: 'Other Requirements (Traces)', value: 'TRACED_TO' },
    ]

    const targetSpecification = ref(null)
    const targetRequirement = ref(null)
    const relation = ref('')
    const includeChildren = ref(true)
    const traceToOriginal = ref(false)
    const copyLinks = ref([])

    const requirementsList = ref([])
    watch(targetSpecification, () => {
      requirementsList.value = []
      if (targetSpecification.value !== null) {
        coreService
          .get(`/v1/legacy/specifications/${targetSpecification.value.id}/requirements`)
          .then(({ data }) => {
            Object.entries(data).forEach(entry => {
              requirementsList.value.push(entry[1])
            })
          })
      }
    })

    const onShow = () => {
      targetSpecification.value = null
      targetRequirement.value = null
      relation.value = 'Child'
      includeChildren.value = false
      traceToOriginal.value = false
      copyLinks.value = linkOptions.map(link => link.value)

      if (props.selectedRequirements.length > 1) {
        titleSuffix.value = `${props.selectedRequirements.length} Requirements`
        bodyText.value = props.selectedRequirements.map(req => `${req.display_id} ${req.object_text}`).join('')
      } else {
        titleSuffix.value = `Requirement ${props.selectedRequirements[0].display_id}`
        bodyText.value = props.selectedRequirements[0].object_text
      }
    }

    const onSubmit = () => {
      loading.value = true
      const payload = {
        requirement_ids: props.selectedRequirements.map(req => req.id),
        target_relation_type: targetRequirement.value ? relation.value.toLowerCase() : 'child',
        target_requirement: targetRequirement.value ? targetRequirement.value.id : targetSpecification.value.id,
        include_children: includeChildren.value,
        trace_to_source: traceToOriginal.value,
        copy_relationship_types: copyLinks.value,
      }
      store.dispatch('requirements/copyRequirement', payload).then(response => {
        context.root.$toast({
          component: ToastificationContent,
          props: {
            title: `Copied ${titleSuffix.value}`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        context.root.$bvModal.hide('copy-requirement-modal')
        context.emit('on-copy')
      }).finally(() => {
        loading.value = false
      })
    }

    return {
      loading,
      titleSuffix,
      bodyText,
      perfectScrollbarSettings,
      requirementTableFields,
      requirementsList,
      linkOptions,

      targetSpecification,
      targetRequirement,
      relation,
      includeChildren,
      traceToOriginal,
      copyLinks,

      onShow,
      onSubmit,
    }
  },
}
</script>
